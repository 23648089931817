*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.responsive-sidebar-logo{
    width: 200px;
}
.responsive-sidebar-iteam{
    padding: 10px;
    transition: 0.5s;
    border-radius: 10px;
    .responsive-sidebar-icon{
        width: 20px;
    }
    .sidebarmenu-icon{
        width: 13px !important;
        height: 13px !important;
        margin-top: 15px;
    }
    
    &:hover{
        background-color: rgba(128, 128, 128, 0.179);
        transition: 0.5s;
    }
}