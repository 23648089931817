* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dashboard-nav {
  border-bottom: 0.5px solid rgba(155, 155, 155, 0.245) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  border: none;

  .nav-menu {
    cursor: pointer;
    svg {
      color: gray !important;
    }
  }
  .notivecation {
    margin-top: 10px;
  }

  .dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .user {
    display: flex;
    align-items: center;
    // padding: 10px;
    // margin-left: 20px;
    text-decoration: none !important;

    .user-name {
      font-size: 14px;
      color: black;
    }

    .logout {
      background-color: red;
      border: 1px solid white;
      color: white;
      transition: 0.2s;

      &:hover {
        border: 1px solid red;
        background-color: white;
        color: red !important;
        transition: 0.2s;
      }
    }
  }
  .responsive-sidebar {
    display: none;
  }
  @media (max-width: 768px) {
    .nav-menu {
      display: none;
    }
    .responsive-sidebar {
      display: block;
    }
  }
  @media (max-width: 992px) {
    display: flex !important;
  }
}
