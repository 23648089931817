*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.dashboard{
    .card-container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        
        .card{
            width: 300px;
            background-color: rgb(59, 199, 246);
            border: none;
            outline: none;
            margin: 10px;

            .card-content{
                padding: 20px;
                h4{
                    color: black;
                }
                p{
                    color: white;
                }
            }
            .card-botton{
                background-color: rgba(0, 0, 0, 0.1);
                text-align: center;
                color: white;
                svg{
                    color: white;
                    margin: 0px 10px;
                }
            }
        }
    }
    .tab-pane,
    .nav-link{
        width: 100% !important;
    }
}