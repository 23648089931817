* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login-page {
  background-image: url("../../Assets/Images/loginPage-banner.jpg");
  background-size: cover;
  height: 100vh;
  color: white !important;

  .login-logo {
    margin-right: 2rem;
  }
  .right {
    width: 500px;
    text-align: center;

    .login-title {
      font-size: 1rem;
      margin-bottom: 8px;
    }
    .form-control {
      width: 100%;
      height: 50px;
      padding: 0.5rem 1rem;
      font-size: 15px;
      line-height: 1.5;
      border-radius: 5px;
      border: none;
      outline: none;
    }

    .password-input {
      display: flex;
      align-items: center;
      .form-control {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
      button {
        padding: 0.8rem 1rem;
        background-color: white;
        border: none;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
    }

    .input-span {
      background-color: #2a34d0;
      height: 50px;
      width: 12%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .eye-icon {
      height: 100%;
      width: 100%;
      padding: 12px;
    }

    .submit-button {
      background-color: #252ab5;
      color: white;
      border: 1px solid white;

      &:hover {
        background-color: #0011ff;
      }
    }
  }

  @media (min-width: 992px) {
    .form-control {
      // width: 60% !important;
    }
    .form-control-password {
      width: 48% !important;
    }
  }

  @media (max-width: 992px) {
    .form-control-password {
      width: 85% !important;
    }
    .input-span {
      width: 15% !important;
    }
  }

  @media (max-width: 768px) {
    .right {
      text-align: center !important;
      margin-top: 20px;
      width: 100% !important;
    }
  }
}
