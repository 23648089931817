* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.cnp {
  margin-right: calc(-0 * var(--bs-gutter-x)) !important;
  margin-left: calc(-0 * var(--bs-gutter-x)) !important;
  input {
    width: 100% !important;
  }
}

.react-time-picker__clear-button {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px 6px;
  border-radius: 5px;
  background-color: whitesmoke;

  &:hover {
    background-color: beige;
  }
}

.react-time-picker__clock-button {
  padding: 4px 6px;
  border-radius: 5px;
  background-color: whitesmoke;

  &:hover {
    background-color: beige;
  }
}

.react-time-picker__button__icon{
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-time-picker__clear-button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-time-picker__inputGroup__input {
  width: 45% !important;
}

.react-time-picker__inputGroup__divider {
  margin: 10px;
}

.layout-body{
  overflow-y: auto;
  height: 90vh;
}

::-webkit-scrollbar{
  width: 5px;
}

::-webkit-scrollbar-thumb{
  background-color: gray;
}