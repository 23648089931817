@import "../../../variables/variables.scss";

.categorys-table{
    width: 100%;
    border: 1.5px solid $border_gray;
    border-radius: 5px;
    th,td{
        padding: 10px !important;
    }

    td{
        font-size: 13px;
    }
    thead{
        border-bottom: 1px solid black;
    }
}