.profile-container{
    height: 40vh !important;
    background-image: url("../../Assets/Images/loginPage-banner.jpg");
    background-size: cover;
    .profile{
        height: 100%;
        color: white;
        display: flex;
        align-items: center;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.322), rgba(255, 255, 255, 0));

        .avatar{
            border-radius: 50%;

            .userAvatar{
                font-size: 80px;
            }
        }
    }
}