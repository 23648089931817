@import "../../../variables/variables.scss";

.category {
  border: 1px solid $border_gray;
  border-radius: 5px;
  padding: 20px;

  .category-field {
    input {
      width: 100%;
    }
  }

  label {
    font-size: 14px;
  }

  input {
    margin: 10px 0px;
    padding: 5px;
    border: 1px solid $border_gray;
    border-radius: 3px;
    width: 100%;
    text-transform: capitalize;

    @media (min-width: 768px) {
      // width: 50%;
    }
  }

  .w-100{
    width: 100% !important;
  }

  .buttons {
    border-top: 1px solid $border_gray;
    button {
      margin-right: 20px;
      margin-top: 10px;
      padding: 3px 10px;
      border: none;
      border-radius: 5px;
      font-size: 13px;

      &:hover {
        background-color: lightgray;
      }
    }
  }
}

.row{
  margin-right: calc(-.0 * var(--bs-gutter-x)) !important;
  margin-left: calc(-.0 * var(--bs-gutter-x)) !important;
}