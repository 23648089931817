.sidebar {
  padding: 10px;
  border-right: 1px solid rgba(155, 155, 155, 0.245) !important;
  width: 250px;
  top: 0;
  left: 0;
  transition: 0.5s !important;
  // background-color: red;
  min-height: 100vh !important;

  .sidebar-logo {
    width: 100%;
    border-bottom: 1px solid rgba(155, 155, 155, 0.245);
    padding-bottom: 30px;

    img {
      width: 100px;
      transition: 0.5s;

    }
  }

  @media (max-width:768px) {
    display: none;
  }
}
.sidebar-icons {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.sidebar-menu-icon {
  font-size: 13px;
  color: black;
}

.sidebar-items {
  padding-left: 0rem;
  padding-top: 10px;
  overflow: hidden !important;

  li {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;

    .sidebar-text {
      text-decoration: none;
      transition: 1s !important;
      color: black;
      font-size: 13px;
    }

    &:hover {
      background-color: rgba(128, 128, 128, 0.179);
    }
  }

  .sidebar-link {
    background-color: red;
    width: 100%;
  }

  .sidebar-content {
    padding: .25rem;
    height: 0;
    overflow: hidden;
  }
}

.active {
  width: 65px !important;
  transition: 0.5s !important;

  .sidebar-logo img {
    width: 30px;
    transition: 0.3s !important;
  }

  .user-name{
    display: none;
  }

  .sidebar-items {

    .sidebar-text,
    .sidebar-menu-icon {
      display: none;
      transition: 1.5s !important;
    }
  }

}